<template>
  <div class="sp_box main">
    <div class="banner_box">
      <div class="swiper_box">
        <img
          v-if="$route.query.cat == 'awards'"
          src="@/assets/images/banner3.jpg"
        />
        <img
          v-if="$route.query.cat == 'festival'"
          src="@/assets/images/banner4.png"
        />
        <img
          v-if="$route.query.cat == 'academy'"
          src="@/assets/images/banner5.jpg"
        />
        <img
          v-if="$route.query.cat == 'media'"
          src="@/assets/images/banner8.png"
        />
      </div>
    </div>
    <div class="tips1">
      <div class="container content">
        <div class="title">
          If you need any support, <br />please contact us at :<a
            href="mailto:international@eciawards.org"
            ><u>international@eciawards.org</u></a
          >
          or fill in the form below, we will get back to you shortly:
        </div>
        <div class="text"></div>
      </div>
    </div>
    <!-- <div class="tips2" >
      <div class="container content">
        <div class="title">Festival support</div>
        <div class="list_">
          <div class="item_">
            <div class="title2">
              How do I book my accommodation for the Festival?
            </div>
            <div class="text">
              Cannes Lions delegates can book hotels and apartments at special
              rates. Cannes Lions works in partnership with bnetwork. Based in
              Cannes, they have many years of experience specialising in hotel
              management solutions for large scale events. Once passes have been
              purchased, you can access the bnetwork website via your account
              where you can find live availability and book rooms. Should you
              have any questions, please email bnetwork.
            </div>
          </div>
          <div class="item_">
            <div class="title2">
              How do I book my accommodation for the Festival?
            </div>
            <div class="text">
              Cannes Lions delegates can book hotels and apartments at special
              rates. Cannes Lions works in partnership with bnetwork. Based in
              Cannes, they have many years of experience specialising in hotel
              management solutions for large scale events. Once passes have been
              purchased, you can access the bnetwork website via your account
              where you can find live availability and book rooms. Should you
              have any questions, please email bnetwork.
            </div>
          </div>
          <div class="item_">
            <div class="title2">
              How do I book my accommodation for the Festival?
            </div>
            <div class="text">
              Cannes Lions delegates can book hotels and apartments at special
              rates. Cannes Lions works in partnership with bnetwork. Based in
              Cannes, they have many years of experience specialising in hotel
              management solutions for large scale events. Once passes have been
              purchased, you can access the bnetwork website via your account
              where you can find live availability and book rooms. Should you
              have any questions, please email bnetwork.
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="tip3">
      <div class="part_form">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <el-form-item
            label="First Name:"
            prop="first_name"
            label-width="150px"
          >
            <el-input v-model="ruleForm.first_name"></el-input>
          </el-form-item>
          <el-form-item label="Last Name:" prop="last_name" label-width="150px">
            <el-input v-model="ruleForm.last_name"></el-input>
          </el-form-item>
          <el-form-item label="Emai:" prop="email" label-width="150px">
            <el-input v-model="ruleForm.email"></el-input>
          </el-form-item>
          <el-form-item
            label="Company:"
            prop="company_name"
            label-width="150px"
          >
            <el-input v-model="ruleForm.company_name"></el-input>
          </el-form-item>
          <el-form-item label="Country:" prop="country" label-width="150px">
            <el-input v-model="ruleForm.country"></el-input>
          </el-form-item>

          <el-form-item
            label="Your Message:"
            prop="message"
            class="textarea"
            label-width="150px"
          >
            <el-input type="textarea" v-model="ruleForm.message"></el-input>
          </el-form-item>
          <div class="button" @click="submitForm('ruleForm')">SUBMIT</div>
        </el-form>
      </div>
    </div>

    <div class="backBtn" @click="pageBack" style="margin: 50px auto"></div>
  </div>
</template>

<script>
import { SUPPORT } from '@/api/footer.js';
export default {
  name: 'Support',
  components: {},
  data() {
    return {
      ruleForm: {
        first_name: '',
        last_name: '',
        email: '',
        company_name: '',
        country: '',
        message: '',
        typs: this.$route.query.cat,
      },
      rules: {
        first_name: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        last_name: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        email: [{ required: true, message: 'Please enter', trigger: 'blur' }],
        company_name: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        country: [{ required: true, message: 'Please enter', trigger: 'blur' }],
        message: [{ required: true, message: 'Please enter', trigger: 'blur' }],
      },
    };
  },
  created() {
    console.log(this.$route.query.cat);
  },
  methods: {
    pageBack() {
      this.$router.go(-1);
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          SUPPORT(this.ruleForm).then(res => {
            console.log(res);
            if (res.errcode == 0) {
              this.$message({
                type: 'success',
                message: 'success',
              });
              this.ruleForm = {
                first_name: '',
                last_name: '',
                email: '',
                company_name: '',
                country: '',
                message: '',
              };
            } else {
              this.$message({
                type: 'warning',
                message: res.errmsg,
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/part.scss';
.sp_box {
  .tips1 {
    background-color: #f4f6f8;
    padding-bottom: 50px;
    .title {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px;
    }
    .text {
      font-size: 18px;
      line-height: 26px;
      text-align: center;
    }
  }
  .tips2 {
    .title {
      font-size: 32px;
      margin-bottom: 30px;
    }
    .list_ {
      .title2 {
        font-size: 18px;
        font-weight: bold;
        position: relative;
        margin-bottom: 20px;
        line-height: 26px;
      }
      .title2::before {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: $blue;
        position: absolute;
        left: -50px;
        top: 8px;
        content: '';
      }
      .item_ {
        margin-bottom: 50px;
        padding-left: 50px;
        .text {
          line-height: 26px;
        }
      }
    }
  }
  .tip3 {
    background-color: #f4f6f8;
    padding: 50px 0;
  }
}
</style>
