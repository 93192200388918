import request from '@/utils/request.js';
const BASEURL = process.env.VUE_APP_BASE_URL;

export function CONTACT(params) {
  return request({
    url: BASEURL + '/home/web/contact',
    method: 'POST',
    params,
  });
}
export function SUPPORT(params) {
  return request({
    url: BASEURL + '/home/web/support',
    method: 'POST',
    params,
  });
}
